import { library } from '@fortawesome/fontawesome-svg-core'
import {faBars, faXmark, faCartShopping, faEllipsis, faTrashCan, faTrash, faTrashAlt,
       faCheck,faSpinner,faCartArrowDown, faUser}  from '@fortawesome/free-solid-svg-icons'
//import {faTrashCan as frTrashCan}  from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faInstagram,faXTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(
    faBars,
    faXmark,
    faCheck,
    faFacebookF,
    faInstagram,
    faXTwitter,
    faCartShopping,
    faEllipsis,
    faTrashCan,
    faTrash,
    faTrashAlt,
    faSpinner,
    faCartArrowDown,
    faUser
)
import  {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import useDataContext from "../hooks/useDataContext";

const  PersistLogin = () => {
    const [isLosding, setIsLoading] = useState(true);
    
    const {auth} = useDataContext();
    const refresh = useRefreshToken()
   
    useEffect(() => {
        let isMounted = true;
        
        const verifyRefreshToken = async  () => {
            try{
                 await refresh();
            } catch(err) {
                console.log(err);
            } finally {
                isMounted && setIsLoading(false);
                //setIsLoading(false)
            }
        }
        
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
        return () => isMounted = false;
        // eslint-disable-next-line
    },[])


   /*  useEffect(()=>{
        //console.log(`isLoading: ${isLosding}`)
        // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    },[isLosding]) */
    /*useEffect(()=>{
        //console.log(`isLoading: ${isLosding}`)
         console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    },[auth])*/

    return (
        <>
        {isLosding 
            ? <p>Loading...</p>
            :<Outlet />
        }
        </>
    )

}
export default PersistLogin
import React from 'react'
import { useLocation, Navigate, Outlet  } from 'react-router-dom'
import useDataContext from '../hooks/useDataContext' 

function RequireAuth({allowedUsers}) {
  const {auth} = useDataContext()
  const location = useLocation()
  ///console.log("rA:",auth)
  return (
    parseInt(auth?.roles) === parseInt(allowedUsers)
    ? <Outlet />
    : <Navigate to="/admin/login" state ={{from:location}} replace /> 
  )
}

export default RequireAuth
import React, {useState, useEffect} from 'react'
import axios from '../api/axiosapi'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import useDataContext from '../hooks/useDataContext'
import { getPageText, checkVATNumber} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/checkoutform.css'

function CheckoutForm(props) {
    const {items, result} = props
    const GDOY = "e4a5ef3351bd504af5fdfa95e1a9239f" // Get DOY
    const DATA_URL="product.php"
    const {lang, clearCart} = useDataContext()
    const content = getPageText('orderForm')
    const defaultOrder={
        id:null,
        email:null,
        tel:null,//
        surname:null,//
        firstName:null,
        address:null,
        city:null,
        postalCode:null,
        receiptType:1,
        vat:null,
        companyName:null,
        companyAddress:null,
        companyCity:null,
        companyPostalCode:null,
        taxOffice:null,
        activity:null,
        orderDate:null
    }
    
    const [order, setOrder]=useState(defaultOrder)
    const [orderError, setOrderError]=useState([])
    
    const [isLoad, setIsLoad] = useState(false)
    const [taxOfficeList, setTaxOfficeList] = useState([])
    const [isValidVAT, setIsValidVAT] =useState(null)
    const [isLoadVAT, setIsLoadVAT]=useState(false)
    const taxOfficeOptions = taxOfficeList.map(t=>({value:t.id, label:t.tax_office_name}))||[]
    

    const noOptionsText=lang==='gr' ? "Δεν υπάρχουν επιλογές": "No Options"
     const receiptOptionList = [
      {value:1, label: lang==='gr'?'Απόδειξη λιανικής':'Retail receipt'},
      {value:11, label: lang==='gr' ? 'Τιμολόγιο':'Invoice' }
    ]

    function  filterOptionFull (option, inputValue){
      //console.log(option)
      const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
      const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
      const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
      const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
      //console.log(option)
      return inputValue ? optionStr.includes(inputStr) : true
    }
    function handleSelectChange(v, fld){
      //console.log(v)
      if(fld==='receiptType'){
        setOrder(prev=>({...prev, receiptType :v?.value||1}))
      }else if(fld==='taxOffice'){
        setOrder(prev=>({...prev, taxOffice :v?.value||null}))
      }
     
    }
    function handleChange(ev){
      const {name, value} = ev.target
      const patternVAT = /^\d{0,9}$/
      const patternTel=/^([0-9]{0,10})(,[0-9]{0,10})*$/gi
      const wordPattern =  /^[\p{L} \-().,/\\]+$/giu
      const pcPattern = /^\d{1,5}$/g
      const addressPattern = /^[\p{L}\p{N}()[\]]+[\p{L}\p{N}()[\].\s&/\\΄]*$/giu

      const nameArr=['surname', 'firstName', 'city', 'companyCity','activity']
      const pcArr = ['postalCode', 'companyPostalCode']
      const addressArr=['address', 'companyAddress']
      if(name==='vat'  && !patternVAT.test(value) && value!==''){
        return
      }else if(name==='tel' && !patternTel.test(value) && value!==''){
        return
      }else if (nameArr.includes(name) && !wordPattern.test(value) && value!==''){
        return
      }else if(pcArr.includes(name) && !pcPattern.test(value) && value!==''){
        return
      }else if(addressArr.includes(name) && !addressPattern.test(value) && value!==''){
        return
      }
      setOrder(prev=>({...prev, [name]:value||null}))
      if(name==='vat'){
        const valid = checkVATNumber(value)
        setIsValidVAT(valid)
      }
      
    }

    function handleFieldFocus(ev){
      const {name} = ev.target
      const err = orderError.filter(o=>o.field!==name)
      setOrderError(err)
    }
    function handleSelectFocus(field){
      const err = orderError.filter(o=>o.field!==field)
      setOrderError(err)
    }

    function checkAndSendOrder(ev){

      const emailPattern=/^([a-z0-9+_\-?&]+)(\.[a-z0-9+_\-?&]+)*@([a-z0-9\-_]+\.)+[a-z]{2,6}$/i
      //const cityPattern = /^[\D]{2,}$/gi
      const patternTel=/^([0-9]{10})(,[0-9]{10})*$/gi
      const sn = /^[\p{L} \-().,/\\]{2,}$/giu
      const fn = /^[\p{L} \-().,/\\]{2,}$/giu
      const c =  /^[\p{L} \-().,/\\]{2,}$/giu
      const cc = /^[\p{L} \-().,/\\]{2,}$/giu
      const pc = /\d{5}/g
      const cpc = /\d{5}/g
      const a  = /^[\p{L}\p{N}()[\]]+[\p{L}\p{N}()[\].\s&/\\΄]*$/giu
      const ca = /^[\p{L}\p{N}()[\]]+[\p{L}\p{N}()[\].\s&/\\΄]*$/giu
      const errorArr = []
      setOrderError([])
       /* if(!order.email || !emailPattern.test(order.email||'')){
        //setOrderError(p=>[...p, {field:"email",msg:"To πεδίο email δεν έχει έγκυρη μπρφή"}])
        errorArr.push({field:"email",msg:"To πεδίο email δεν έχει έγκυρη μορφή"})
       
      }
      if(!order.tel || !patternTel.test(order.tel||'')){
        //setOrderError(p=>[...p, {field:"tel", msg:"Οι αριθμοι τηλεφώνου πρέπει να έχουν 10 ψηφία ο καθένας"}])
        errorArr.push({field:"tel", msg:"Οι αριθμοι τηλεφώνου πρέπει να έχουν 10 ψηφία ο καθένας"})
      }
      if(!order.surname || !sn.test(order.surname||'')){
        //setOrderError(p=>[...p, {field:"surname", msg:"Το Επώνυμο αποτελείται από τουλάχιστο 2 γράμματα"}])
        errorArr.push({field:"surname", msg:"Το Επώνυμο αποτελείται από τουλάχιστο 2 γράμματα"})
      }
       if(!order.firstName || !fn.test(order.firstName||'')){
        //console.log(wordPattern.test(order.firstName||''))
        //setOrderError(p=>[...p, {field:"firstName", msg:"Το Όνομα αποτελείται από τουλάχιστο 2 γράμματα"}])
        errorArr.push( {field:"firstName", msg:"Το Όνομα αποτελείται από τουλάχιστο 2 γράμματα"})
      }
      if(!order.city || !c.test(order.city||'')){
        //console.log(wordPattern.test(order.city||''))
        //setOrderError(p=>[...p, {field:"city", msg:"Η πόλη αποτελείται από τουλάχιστο 2 γράμματα"}])
        errorArr.push({field:"city", msg:"Η πόλη αποτελείται από τουλάχιστο 2 γράμματα"})
      } 
      if(!order.address || !a.test(order.address||'')){
        //setOrderError(p=>[...p, {field:"address", msg:"Η διέυθυνση δεν έχει έγκυρη μορφή"}])
        errorArr.push({field:"address", msg:"Η διέυθυνση δεν έχει έγκυρη μορφή"})
      }
      if(!order.postalCode || !pc.test(order.postalCode||'')){
        //setOrderError(p=>[...p, {field:"postalCode", msg:"Ο Τ.Κ. δεν είναι έγκυρος"}])
        errorArr.push({field:"postalCode", msg:"Ο Τ.Κ. δεν είναι έγκυρος"})
      }
      if(order.receiptType===11){
        if(!order.companyName?.trim() ){
          //setOrderError(p=>[...p, {field:"companyName", msg:"Το όνομα της εταιρείας πρέπει να συμπληρωθεί"}])
          errorArr.push({field:"companyName", msg:"Το όνομα της εταιρείας πρέπει να συμπληρωθεί"})
        }
        if(!checkVATNumber(order.vat||'')){
          setOrderError(p=>[...p, {field:"vat", msg:"Ο ΑΦΜ δεν είναι έγκυρος"}])
        }
        if(!order.companyAddress || !ca.test(order.companyAddress||'')){
          //setOrderError(p=>[...p, {field:"companyAddress", msg:"Η διέυθυνση δεν έχει έγκυρη μορφή"}])
          errorArr.push({field:"companyAddress", msg:"Η διέυθυνση δεν έχει έγκυρη μορφή"})
        }
        if(!order.companyCity || !cc.test(order.companyCity||'')){
          //setOrderError(p=>[...p, {field:"companyCity", msg:"Η πόλη αποτελείται από τουλάχιστο 2 γράμματα"}])
          errorArr.push({field:"companyCity", msg:"Η πόλη αποτελείται από τουλάχιστο 2 γράμματα"})
        }
        if(!order.companyPostalCode || !cpc.test(order.companyPostalCode||'')){
          //setOrderError(p=>[...p, {field:"companyPostalCode", msg:"Ο Τ.Κ. δεν είναι έγκυρος"}])
          errorArr.push({field:"companyPostalCode", msg:"Ο Τ.Κ. δεν είναι έγκυρος"})
        }
        if(!order.taxOffice){
          //setOrderError(p=>[...p, {field:"taxOffice", msg:"Πρέπει να επιλέξετε ΔΟΥ"}])
          errorArr.push({field:"taxOffice", msg:"Πρέπει να επιλέξετε ΔΟΥ"})
        }
      }  */
      //console.log("errorArr:",errorArr)
      setOrderError(errorArr)
      if(!errorArr.length){
        async function  sendOrder(lng){
          setIsLoad(true)
          const SNDORD = "9c51d4a2c2c3445c720b2c1cea4ca848" // SeND ORDer
          const orderCode= uuidv4()?.replaceAll("-","")||null
          //console.log(orderCode)
          try{
            const response = await axios.post(DATA_URL, {lang:lng,order,items, orderCode, act:SNDORD})
            const { error, message} = response.data
            const errResp = {num:error, msg:message}
            result(errResp)
            clearCart()
          }
          catch(err){
              const {error, orderError, message} =err?.response?.data
              if(error === 400){
                setOrderError(orderError)
              }else{
                const errResp = {num:error, msg:message}
                result(errResp)
              }
              //console.log(error , message)
          }
          finally{
              setIsLoad(false)
          }
        }
        sendOrder(lang) 
      }

      
    }

  const customStyles = {
    control: (base, state) => ({
        ...base,
        width:'100%',
        fontFamily:'Open Sans',
        fontSize:'20px',
        fontWeight:'400',
        //border:'2px solid #adadad',
        border:'none',
        borderBottom:'2px solid #adadad',
        boxShadow:0,
        '&:hover':{
            borderColor:'#adadad',
          }
    }),
    input: base=>({
      ...base,
      padding:'0',
     
    }),
    menu: base =>({
        ...base,
        width:'100%', 
        //border:'1px solid var(light-olive)',
        //border:'none',
        border:'2px solid #adadad',
        outline:'none',
        boxShadow:'none',
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
          fontFamily:'Open Sans',
          fontSize:'18px',
          fontWeight:'400',
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? 'var(--dark-olive)'
            : isFocused
            ? 'var(--light-olive)'
            : undefined,
            color: isDisabled
            ? undefined
            : isSelected
            ? '#fff':undefined,
          cursor: isDisabled ? 'not-allowed' : 'default',
        }
      }
  }

  useEffect(() => {
    if(order.receiptType===11){
      async function getTaxOffice(){ 
        try {
          const response= await axios.post(DATA_URL, {act:GDOY})
          const {doyList} = response.data
          //console.log(doyList)
          setTaxOfficeList(doyList)
        }catch(err){
          const {error, message} =err?.response?.data
          console.log(error , message)
        }
      }
      //console.log('done')
      getTaxOffice()
    }
  
  }, [order.receiptType])

   useEffect(() => {
    if(isValidVAT){
       async function  getVatData(cc, vn){
        setIsLoadVAT(true)
        const vatNum = vn.replaceAll(' ','')
        const CGVAT = "500ddee791a46eae94ee442571c0cf1a" // Check Get VAT
        try{
           const response = await axios.post(DATA_URL, {cc:cc, vn:vatNum, act:CGVAT})
           const {companyName, companyAddress, companyPostalCode,companyCity} = response.data
           setOrder(prev=>({...prev, companyName, companyAddress, companyPostalCode, companyCity}))
        }
        catch(err){
            const {error, message} =err?.response?.data
            console.log(error , message)
        }
        finally{
            setIsLoadVAT(false)
        }
      }
      getVatData('EL', order.vat) 
    }
  
  }, [isValidVAT, order.vat]) 
  
  
 // const errorContent = orderError.map((o,i)=>(<li key={`kr_${i}`}>{o.msg}</li>))
  

  return (
    <div className='checkout-form'>
        <h1>{content[lang].title}</h1>
        <p className="req-info"><span>* </span>Υποχρεωτικά πεδία</p>
        <div className='field-group'>
          <fieldset >
            <legend>Στοιχεία Επικοινωνίας</legend>
            <div className={`checkout-field ${orderError.find(o=>o.field==='surname')?'err-fld':''}`}>
              <label htmlFor='surname' className='req'>{content[lang].surname}</label>
              <input type="text" name="surname" id="surname" value={order.surname||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='surname')?<p className='err-msg'>{orderError.find(o=>o.field==='surname')?.msg||''}</p>:''}
            </div>
            <div className={`checkout-field ${orderError.find(o=>o.field==='firstName')?'err-fld':''}`}>
              <label htmlFor='first_name' className='req'>{content[lang].firstName}</label>
              <input type="text" name="firstName" id="firstName" value={order.firstName||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='firstName')?<p className='err-msg'>{orderError.find(o=>o.field==='firstName')?.msg||''}</p>:''}
            </div>
            <div className={`checkout-field ${orderError.find(o=>o.field==='email')?'err-fld':''}`}>
              <label htmlFor='email' className='req'>{content[lang].email}</label>
              <input type="text" name="email" id="email" value={order.email ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='email')?<p className='err-msg'>{orderError.find(o=>o.field==='email')?.msg||''}</p>:''}
            </div>
            <div className={`checkout-field ${orderError.find(o=>o.field==='tel')?'err-fld':''}`}>
              <label htmlFor='tel' className='req'>{content[lang].tel}</label>
              <input type="text" name="tel" id="tel" value={order.tel ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='tel')?<p className='err-msg'>{orderError.find(o=>o.field==='tel')?.msg||''}</p>:''}
            </div>
            </fieldset>
            <fieldset>
            <legend>Στοιχεία Αποστολής</legend>
            <div className={`checkout-field ${orderError.find(o=>o.field==='address')?'err-fld':''}`}>
              <label htmlFor='address' className='req'>{content[lang].address}</label>
              <input type="text" name="address" id="address" value={order.address ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)} />
              {orderError.find(o=>o.field==='address')?<p className='err-msg'>{orderError.find(o=>o.field==='address')?.msg||''}</p>:''}
            </div>
            <div className={`checkout-field ${orderError.find(o=>o.field==='city')?'err-fld':''}`}>
              <label htmlFor='city' className='req'>{content[lang].city}</label>
              <input type="text" name="city" id="city" value={order.city ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='city')?<p className='err-msg'>{orderError.find(o=>o.field==='city')?.msg||''}</p>:''}
            </div>
            <div className={`checkout-field ${orderError.find(o=>o.field==='postalCode')?'err-fld':''}`}>
              <label htmlFor='postalCode' className='req'>{content[lang].postalCode}</label>
              <input type="text" name="postalCode" id="postalCode" value={order.postalCode ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
              {orderError.find(o=>o.field==='postalCode')?<p className='err-msg'>{orderError.find(o=>o.field==='postalCode')?.msg||''}</p>:''}
            </div>
          </fieldset>
          
          <fieldset>
          <legend>Στοιχεία Τιμολόγισης</legend>
            <div className='checkout-field'>
              <label className='req'>{content[lang].receiptType}</label>
              <Select 
                name='receiptType'
                className='r-sel'
                options={receiptOptionList} 
                isClearable={true}
                isMulti={false}
                value={receiptOptionList.find(o=>o.value === order?.receiptType)||null}
                onChange={(v)=>handleSelectChange(v, 'receiptType')}
                noOptionsMessage={()=>noOptionsText}
                menuPlacement='auto'
                filterOption = {filterOptionFull}    
                styles={customStyles}
                placeholder={''}
                //isDisabled={editMode.progress || editMode.edit===false ? false:true}
              />
            </div>
            { order.receiptType === 11 ?
              <div className='invoice-wraper'>
                <div className={`checkout-field ${orderError.find(o=>o.field==='vat')?'err-fld':''}`}>
                  <label htmlFor='vat' className='req'>
                    {content[lang].vat}
                    {
                      isLoadVAT 
                      ? <FontAwesomeIcon icon="fa-solid fa-spinner" spin className='load-vat-indicator'/>
                      : 
                      isValidVAT ?
                        <FontAwesomeIcon icon="fa-solid fa-check"  className='valid-vat'/>
                        :
                        isValidVAT ===false ?
                          <FontAwesomeIcon icon="fa-solid fa-xmark"  className='invalid-vat'/>
                          :''
                    }
                  </label>
                  <input type="text" name="vat" id="vat" value={order.vat||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
                  {orderError.find(o=>o.field==='vat')?<p className='err-msg'>{orderError.find(o=>o.field==='vat')?.msg||''}</p>:''}
                </div>
                <div className={`checkout-field ${orderError.find(o=>o.field==='companyName')?'err-fld':''}`}>
                  <label htmlFor='companyName' className='req'>{content[lang].companyName}</label>
                  <textarea name="companyName" id="companyName" value={order.companyName||''} onChange={(e)=>handleChange(e)}  onFocus={(e)=>handleFieldFocus(e)} spellCheck={false} />
                  {orderError.find(o=>o.field==='companyName')?<p className='err-msg'>{orderError.find(o=>o.field==='companyName')?.msg||''}</p>:''}
                </div>
                <div className={`checkout-field ${orderError.find(o=>o.field==='companyAddress')?'err-fld':''}`}>
                  <label htmlFor='companyAddress' className='req'>{content[lang].companyAddress}</label>
                  <input type="text" name="companyAddress" id="companyAddress" value={order.companyAddress||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
                  {orderError.find(o=>o.field==='companyAddress')?<p className='err-msg'>{orderError.find(o=>o.field==='companyAddress')?.msg||''}</p>:''}
                </div>
                <div className={`checkout-field ${orderError.find(o=>o.field==='companyCity')?'err-fld':''}`}>
                  <label htmlFor='companyCity' className='req'>{content[lang].companyCity}</label>
                  <input type="text" name="companyCity" id="companyCity" value={order.companyCity ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
                  {orderError.find(o=>o.field==='companyCity')?<p className='err-msg'>{orderError.find(o=>o.field==='companyCity')?.msg||''}</p>:''}
                </div>
                <div className={`checkout-field ${orderError.find(o=>o.field==='companyPostalCode')?'err-fld':''}`}>
                  <label htmlFor='companyPostalCode' className='req'>{content[lang].companyPostalCode}</label>
                  <input type="text" name="companyPostalCode" id="companyPostalCode" value={order.companyPostalCode ||''} onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFieldFocus(e)}/>
                  {orderError.find(o=>o.field==='companyPostalCode')?<p className='err-msg'>{orderError.find(o=>o.field==='companyPostalCode')?.msg||''}</p>:''}
                </div>
                <div className='checkout-field'>
                  <label htmlFor='activity'>{content[lang].activity}</label>
                  <input type="text" name="activity" id="activity" value={order.activity||''} onChange={(e)=>handleChange(e)} />
                </div>
                <div className={`checkout-field ${orderError.find(o=>o.field==='taxOffice')?'err-fld':''}`}>
                  <label className='req'>{content[lang].taxOffice}</label>
                  <Select 
                    name='taxOffice' 
                    className='r-sel'
                    options={taxOfficeOptions} 
                    isClearable={true}
                    isMulti={false}
                    value={taxOfficeOptions.find(t=>t.value === order?.taxOffice)||null}
                    onChange={(v)=>handleSelectChange(v, 'taxOffice')}
                    noOptionsMessage={()=>noOptionsText}
                    menuPlacement='auto'
                    filterOption = {filterOptionFull}    
                    styles={customStyles}
                    placeholder={''}
                    onFocus={()=>handleSelectFocus('taxOffice')}
                    //isDisabled={editMode.progress || editMode.edit===false ? false:true}
                  />
                  {orderError.find(o=>o.field==='taxOffice')?<p className='err-msg'>{orderError.find(o=>o.field==='taxOffice')?.msg||''}</p>:''}
                </div>
              </div>:''
            }
          </fieldset>
        </div>
        <div className='send-order'>
          <button type='button' className='send-order-btn' disabled={isLoad} onClick={(e)=>checkAndSendOrder(e)}>
            {content[lang].sendOrder}
            {isLoad ? <FontAwesomeIcon icon="fa-solid fa-spinner" spin className='load-vat-indicator'/>:''}
          </button>
        </div>
        <div>
          {
            orderError.length >0 ?
            <p className='gen-err-msg'>
              Μερικά πεδία δεν έχουν συμπληρωθεί σωστά
            </p>
            :''
          }
        </div>
    </div>
  )
}

CheckoutForm.defaultProps={
  items:[]
}

export default CheckoutForm
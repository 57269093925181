import React from 'react'
import {Link,useLocation}  from 'react-router-dom'

function AdminContent(props) {
  const location = useLocation()
  return (
    <>
        <h2>Admin Page here...</h2>
        <Link to="/">Return</Link>
        <Link to="/admin/login" state={{ from: location}} replace >Login</Link>
    </>
  )
}

export default AdminContent
//attached axios interceptors to the axios instance
import { useEffect } from "react";
import { axiosPrivate } from "../api/axiosapi";
import useRefreshToken from "./useRefreshToken";
import useDataContext from '../hooks/useDataContext'

const useAxiosPrivate = () => {
    const  refresh = useRefreshToken()
    const {auth} = useDataContext()
    

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config =>{
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config
            }, (error) => Promise.reject(error)
        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if(error?.response?.status === 403 && !prevRequest?.sent){
                    prevRequest.sent = true
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
                    return axiosPrivate(prevRequest)
                }
                return Promise.reject(error)
            }
        )
        //clean up function of useEffect
        return ()=>{
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    },[auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate
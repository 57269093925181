import React, {useState} from 'react'
import {Link, NavLink, Outlet } from 'react-router-dom'
import logo from './media/img/Aeoliva-olive-oil-logo.svg'
//import logoIcon from './media/img/Aeoliva_Logo_small.svg'
//import logoText from './media/img/Aeoliva_Logo_Text.svg'
//import SmallCart from './components/SmallCart'
import './utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useDataContext from './hooks/useDataContext'

function Layout() {
    const {lang, changeLang, getTotalItems} = useDataContext()
    const [isOpen, setIsOpen] = useState(false)

    
    const itemsCounter = getTotalItems()
  return (
    <div className={`container ${isOpen ?'show-nav':''}`}>
        <header>
            <Link to="home" >
                <img src={logo} className='logo' alt="AEOLIVA Logo" />
            </Link>
            <nav className={`main-nav ${isOpen?'open-nav':''}`}>
                <FontAwesomeIcon icon={['fas', 'xmark']} className='exit' onClick={()=>setIsOpen(false)}/>
                <ul>
                    <li><NavLink to='about' className={({isActive}) => isActive ? "active":""} onClick={()=>setIsOpen(false)}>About</NavLink></li>
                    <li><NavLink to='products' onClick={()=>setIsOpen(false)}>Products</NavLink></li>
                    <li><NavLink to='history' onClick={()=>setIsOpen(false)}>History</NavLink></li>
                    <li><NavLink to='contact' onClick={()=>setIsOpen(false)}>Contact</NavLink></li>
                </ul>
            </nav>
            <div className='language'>
                <button className={`gb ${lang==='gb'?'active-lang':''}`} onClick={(e)=>changeLang('gb')}>EN</button>
                <button className={`gr ${lang==='gr'?'active-lang':''}`} onClick={(e)=>changeLang('gr')}>GR</button>
            </div>
            <div className='cart'>
                <NavLink to='cart' className='cart-link' >
                    <FontAwesomeIcon icon="fa-solid fa-cart-shopping" />
                    {itemsCounter ? <div className='item-counter'>{itemsCounter}</div>:''}
                </NavLink>
            {/*  <SmallCart /> */}
            </div>
            <FontAwesomeIcon icon={['fas', 'bars']} className='open' onClick={()=>setIsOpen(true)}/>
            
        </header>
    
        <Outlet />
        <div className='footer'>
            <a href={lang==="gb" ? 'https://www.idea-no.com':'https://www.idea-no.com/el/graphic-design-athens-greece.php'} target='_blank' rel='noreferrer'>
                NO IDEA graphic design
            </a>
            <div className='footer-social'>
                <a href='https://www.idea-no.com' target='_blank' rel='noreferrer noopener'>
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                </a>
                <a href='https://www.idea-no.com' target='_blank' rel='noreferrer noopener'>
                    <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
                </a>
                <a href='https://www.idea-no.com' target='_blank' rel='noreferrer noopener'>
                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                </a>
                <Link to="admin">admin</Link>
            </div>
        </div>
    </div>
  )
}

export default Layout
import React from 'react'
import { getPageText } from '../utils/util'
import useDataContext from '../hooks/useDataContext'
import "../style/history.css"

function History(props) {

  const {lang} = useDataContext()
  const content = getPageText('history')

  return (
    <main className='history-main'>
      <div className='history-left-side'>
        <h1>{content[lang].title}</h1>
        <h5>{content[lang].subtitle}</h5>
        <img className='history-img' src={process.env.PUBLIC_URL + "/icons/Aeoliva-olive-oil-history.jpg"} alt="Olive harvest"/>
      </div>
      <div className="history-right-side">
        <p>
          In the enchanting region of Geraki, Ilia, our journey begins with olive groves that have been nurtured 
          and cherished through the generations. Our father, a true steward of the land, planted these olive 
          trees at the highest point of this pristine corner of Greece, creating a living legacy. From the earliest 
          days of our youth, we joined our father in tending to these groves, and in doing so, we developed a 
          profound connection with the trees and the breathtaking landscape that surrounds us.
        </p>
        <p>
          Our estates, situated 4 kilometers away from our village, rest atop a picturesque hill that overlooks the 
          vast expanse of the Ionian Sea, where Zakynthos emerges on the horizon. This vantage point offers 
          not only a stunning view but also a unique microclimate that blesses our olive trees with optimal 
          conditions to thrive. We've been nurturing and caring for these olives from a tender age, tending to 
          the groves with dedication and love.
        </p>
        <p>
          As we grew and honed our expertise, we became captivated by the world of olive oil, not only for its 
          culinary significance but also for the cultural and historical heritage it embodies. We embarked on a 
          journey of exploration and education, attending seminars on olive oil tasting and delving into the craft 
          of production. It was through this passion and knowledge that we realized our calling - to share the 
          fruits of our labor with the world.
        </p>
        <p>
          From this deep-rooted love and a desire to preserve the time-honored traditions of our land, Aeoliva 
          was born. Our mission is to craft extraordinary Extra Virgin Olive Oil that carries within it the essence 
          of Geraki, Ilia. The olives from these groves are handpicked at the perfect moment of ripeness, 
          capturing the full flavors and aromas of the region. Our commitment to quality, heritage, and 
          innovation has led us to establish our family-owned business, where we handle every aspect of the 
          production process.
        </p>
        <p>
          Aeoliva Extra Virgin Olive Oil is more than just a product; it's a labor of love, a journey of knowledge, 
          and a dedication to preserving the traditions that have shaped our lives. With every drop, we invite 
          you to savor the culture, history, and nature of Geraki, Ilia. It's a taste of Greece that we are proud to 
          share with the world.
        </p>

      </div>
    </main>
  )
}

export default History
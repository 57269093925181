import React, {useState, useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useDataContext from '../hooks/useDataContext'
import Pagination from './Pagination'
import "../style/orders.css"


function Orders() {
  const {setAuth} = useDataContext()
  const axiosPrivate = useAxiosPrivate()
  const location = useLocation()
  const searchKey = `${location.pathname}-search`
  const defaultSearch={
    rpp:50,
    page:1
  }
  const timer = useRef(null)

  const [search, setSearch] = useState(sessionStorage.getItem(searchKey)?JSON.parse(sessionStorage[searchKey]):defaultSearch)
  const [orders, setOrders]=useState([])
  const [orderProducts,setOrderProducts]=useState([])
  const [totalRows,setTotalRows]=useState(0)
  const [go, setGo] = useState(0)

  function storeLocal(){
    //console.log()
    sessionStorage.setItem(location.pathname, window.scrollY)
    sessionStorage.setItem(searchKey, JSON.stringify(search))
}
function clearSearch(ev){
  //setSearch(initSearch())
  setSearch(search)
  sessionStorage.removeItem(searchKey)
}

function handleSearch(ev){
  //setGo(1);
  setSearch(prev=>({...prev, page:1}))
  setGo(2)
}

function changePage(ev){
  const {value}=ev.target
  setSearch(prev=>({...prev,page:value}))
  setGo(2)
}

  useEffect(()=>{

    function handleScroll(){
      const position = sessionStorage.getItem(location.pathname)
      if(position){
          timer.current = setTimeout(()=>{
          window.scrollTo(0,parseInt(position))
          sessionStorage.removeItem(location.pathname)
          },0)
          //console.log('pos:' , parseInt(position))
      }
    }
    const controller = new AbortController();
    if(go){
      if(go === 2){
        window.scrollTo(0,0)
        //console.log('zero')
      }
      sessionStorage.setItem(searchKey, JSON.stringify(search))
      
      axiosPrivate.post("/admin.php",{search},{signal:controller.signal})
      .then(response=>{
        const {accessToken, orders, orderProducts, totalRows} = response.data
        setOrders(orders)
        setOrderProducts(orderProducts)
        setAuth(prev=>({...prev,accessToken}))
        setTotalRows(totalRows)
        if(go === 1){
          handleScroll()
        }
        setGo(0)      
      })
      .catch(err=>console.log(err))
      //.finally(()=>setLoading(false)) 
    }
    return () => {
      controller.abort();
    }
  },[go, search, setAuth, axiosPrivate, searchKey, location.pathname])

  useEffect(() => {
    setGo(1)
    return () => {
      if(timer.current)
          clearTimeout(timer.current)
      }
  }, [])

  const orderContent= orders.length ? orders.map((o,i)=>{
    const orderDetails = orderProducts.filter(op=>op.order_id===o.id)
    return (
    <li key={`ko_${i}`}>
      <div className="ord-container">
        <div className='ord-date'>{o.order_date}</div>
        <div className='ord-customer'>
          <p className='ord'>{`${o.surname} ${o.first_name}`}</p>
          {o.company_name ? <p className='ord'>{o.company_name}</p>:''}
        </div>
        <div className="ord-com">
          <p className='ord'>{o.email}</p>
          <p className='ord'>{o.tel}</p>
        </div>
        <div className="ord-value">
          <span>(τμχ {o.total_products})</span> {o.total_cost} €
        </div>
      </div>
      <div className="ord-details-cont">
        
      </div>
    </li>
  )}):''



  return (
    <div className='order-list-container'>
      <h2>Orders...</h2>
      <button type='button' onClick={(e)=>handleSearch(e)}>Search</button>
      <ul className='orders-list'>
        {orderContent}
      </ul>
      <div>
        {orders?.length?orders.length:''} ({parseInt(search.page -1)*search.rpp  + 1 } έως {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) από {totalRows}  
      </div>
      <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
    </div>
  )
}

export default Orders
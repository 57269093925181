import axios from 'axios'

//const DOMAIN = "http://localhost/aeoliva"
const DOMAIN = "https://aeoliva.com"

const BASE_URL = DOMAIN + '/api/'

export default  axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'application/json',
    },
})

export const axiosPrivate = axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'application/json'
    },
    withCredentials:true
})

export const domainName = `${DOMAIN}`
export const baseURL = `${BASE_URL}`
import React, {createContext, useState, useEffect} from 'react'
import crc32 from 'crc/crc32'; 

const DataContext = createContext({})

export const DataProvider = ({children}) =>{
   
  const [auth, setAuth]= useState({})
  const languages = ['gr', 'gb']
    // change for initial / default state last expr between gr / gb
  const language = localStorage?.getItem('lang') && languages.includes(localStorage.getItem('lang')) ?localStorage.getItem('lang'):'gb' //default gb
  
  //const cartItemsDefault = localStorage.getItem('cart') && JSON.parse(localStorage.getItem('cart'))? (JSON.parse(localStorage.getItem('cart')) ):[]
  function checkCartInlocalStorage(){
    if(localStorage.getItem('cart') && localStorage.getItem('tokenCart')){
      try{
        const num = JSON.parse(localStorage.getItem('cart'))
        const crc = crc32(localStorage.getItem('cart')).toString(16)
        const tc = localStorage.getItem('tokenCart')
        return crc === tc ? num :[] 
      }catch(e){
        return []
      }
    }
    return []
  }
  const cartItemsDefault = checkCartInlocalStorage()
  localStorage.setItem('lang',language)
  const [lang, setLang] = useState(language);  
  const [cartItems, setCartItems] =useState(cartItemsDefault)       
  
  function changeLang(lng){
    localStorage.setItem("lang",lng)
    setLang(lng)
  }



  function increaseCartItems(id){
    const findItem = cartItems.find(item=>parseInt(id)===parseInt(item.id))
    if(findItem){
        setCartItems(prev=>prev.map(item=>{
          if(findItem.id===item.id)
            return {...item, qty: item.qty+1}
          else
            return item
        }))
    }else{
      setCartItems(prev=>([...prev,{id:id, qty:1}]))
    }
    
  }

  function decreaseCartItems(id){
    const findItem = cartItems.find(item=>parseInt(id)===parseInt(item.id)) 
    //if(findItem && findItem.qty > 1){
      if(findItem ){
      setCartItems(prev=>prev.map(item=>{
        if(findItem.id===item.id){
          const decqty = item.qty > 1 ? item.qty-1 : 1
          return {...item, qty: decqty}
         } else{
          return item
         }
      }))
    }
    /*else if(findItem && findItem.qty === 1){
      setCartItems(prev=>prev.filter((item)=>item.id!==findItem.id))
      //setCartItems(prev=>([...prev,{id:id, qty:1}]))
    }*/
  }

  function updateCartItemsQuantity(id, qty){
    const findItem = cartItems.find(item=>parseInt(id)===parseInt(item.id))
    if(findItem ){
      setCartItems(prev=>prev.map(item=>{
        if(parseInt(findItem.id)===parseInt(item.id))
          return {...item, qty:qty||1}
        else
          return item
      }))
    }
  }

  function getCartItem(id){
      const cartItem =  cartItems.find(i=>parseInt(i.id)===parseInt(id))
      return cartItem || null
  }

  function getTotalItems(){
    return cartItems.reduce((acc,item)=>item.qty + acc,0)
    
  }

  function removeCartItems(id){
    setCartItems(prev=>prev.filter((item)=>item.id!==id))
  }

  function removeNotFoundItems(arr){
    if(arr.length > 0){
      setCartItems(prev=>prev.filter((item)=>!arr.includes(item.id)))
    }
  }

  function clearCart(){
    setCartItems([])
  }

  useEffect(()=>{
    const strjson=JSON.stringify(cartItems)
    localStorage.setItem('cart',strjson )
    localStorage.setItem('tokenCart', crc32(strjson).toString(16))

  },[cartItems])

                                                                                                          
  return (
    <DataContext.Provider 
      value={{auth, setAuth, lang, changeLang, cartItems, increaseCartItems, decreaseCartItems, removeCartItems, getTotalItems, getCartItem, removeNotFoundItems, updateCartItemsQuantity, clearCart}}
    >
        {children}
    </DataContext.Provider>
  )
}

export default DataContext
import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axiosapi'
import useDataContext from '../hooks/useDataContext' 
import { getPageText, currencyFormat ,fullTextGR} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CartItem from './CartItem'
import CheckoutForm from './CheckoutForm'
import '../style/cart.css'
import Currency from 'currency.js'


function Cart(props) {
    const navigate = useNavigate();
    const DATA_URL="product.php"
    const GCT = "14f8230b32d89c2228e66fce1ebc828c" // Get CarT
    const {lang, getTotalItems,cartItems, removeNotFoundItems} = useDataContext()
    const totalItems = getTotalItems()
    const locale = lang==='gr'?'el-GR':'en-US'
    const content = getPageText('cart')

    const [cartItemsWithPrice, setCartItemsWithPrice] = useState([])
    const [isLoad, setIsLoad]=useState(false)
    const [isCheckout, setIsCheckout] = useState(false)
    const [err, setErr] = useState({num:null,msg:null})

    const cartTitle = totalItems ? 
         `${totalItems} ${totalItems > 1 ? content[lang].pitem : content[lang].sitem}` 
        :content[lang].empty

    function checkOut(e){
        //console.log(cartItemsWithPrice)
        //console.log(cartItems)
        setIsCheckout(true)
    }
    
    useEffect(() => {
        async function getCart(){ 
            setIsLoad(true)
            try {
                const cartId=cartItems.map(c=>c.id)
                const response= await axios.post(DATA_URL, {act:GCT, cart:cartId})
                const {itemsWithPrice} = response.data
                const tempCart= cartItems.map(ci=>{
                    const ip= itemsWithPrice.find(i=>parseInt(ci.id)===parseInt(i.id))
                    if(ip){
                        return ({...ci, des:ip?.des||null, price:ip?.price||null, img:ip?.img_url||null})
                    }else{
                        return ({...ci, qty:0, des:null, price:null,img:null})
                    }
                })
                
                setCartItemsWithPrice(tempCart.filter(i=>i.qty!==0))
                removeNotFoundItems(tempCart.filter(i=>i.qty===0).map(j=>j.id))


                
            }catch(err){
            const {error, message} =err?.response?.data
            console.log(error , message)
            }finally{
                setIsLoad(false)
            }
        }
        if(cartItems.length){
            getCart()
        }else{
            setCartItemsWithPrice([])
        }
        
        
    }, [cartItems, removeNotFoundItems])
        //console.log(cartItemsWithPrice)

    const cartContent = cartItemsWithPrice.sort((a,b)=>{
        if(a.id < b.id){
            return -1
        }else if (a.id > b.id){
            return 1
        }else{
            return 0
        }

    }).map(item =>
        <CartItem key={`k${item.id}`} item={item} locale={locale} checkout={isCheckout}/>
        )

    const cartTotal =cartItemsWithPrice.reduce((acc, c)=>{
        const p=Currency(c.qty).multiply(c.price).value
        return Currency(acc).add(p).value
    },0.0)

    function checkoutResult(err){
        setErr(err)
    }
    
    const checkoutInfo = err.msg ? err.msg.split('\n').map((e,i)=><p key={`r${i}`} className={`${err.num > 0?'err':'suc'}`}>{e}</p>):''
  return (
    <main className='cart-main'>
        <h1>{cartTitle}</h1>
        {/*
            isLoad ?
             <div className='loader'>
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
            </div> 
            
        :*/
            totalItems ?
            <div>
                <ul className='cart-list'> 
                    {cartContent}
                </ul>
                <div className='cart-total'>
                    <div className='cart-total-des'>{content[lang].total}</div>
                    {cartTotal ? <div className="cart-total-amount">{currencyFormat(cartTotal,locale)}</div>:''}
                    <p className='cart-total-fulltext'>
                        {cartTotal 
                        ? 
                        `#${fullTextGR(cartTotal, lang)}#`
                        :
                        'Κατόπιν συμφωνίας' 
                        }
                    </p>
                    {cartItemsWithPrice.filter(i=>!i?.price).length > 0 && cartTotal ?
                        <p className='cart-total-fulltext'>
                            *(Τιμή συμφωνίας για τα προϊόντα που δεν έχουν τιμολογηθεί)
                        </p>
                        :''
                    }
                    
                </div>
                {isCheckout ? 
                    <CheckoutForm items={cartItems} result={checkoutResult}/>
                :
                <div className='cart-action'>
                    <button className="continue-shop" onClick={()=>navigate('/products')}>{content[lang].continueShop}</button>
                    <button className="checkout" onClick={(e)=>checkOut(e)}>{content[lang].checkout}</button>
                </div>
                }
            </div>
            :
            <div className='cart-action'>
                <button className="continue-shop" onClick={()=>navigate('/products')}>{content[lang].continueShop}</button>
            </div>
        }
        <div>
            {checkoutInfo}
        </div>
        
    </main>

  )
}

export default Cart
import React from 'react'
import { getPageText } from '../utils/util'
import useDataContext from '../hooks/useDataContext'
import '../style/about.css'

function About(props) {
  const {lang,auth} = useDataContext()
  const content = getPageText('about')
  console.log("about:",auth)
  return (
    <main className="about-main">
      <div className='about-text'>
        <h1>{content[lang].hLine1}</h1>
        <h1>{content[lang].hLine2}</h1>
        <h1>{content[lang].hLine3}</h1>
        <h5>{content[lang].rLine1}<br/>{content[lang].rLine2}</h5>
        <p>
          {content[lang].pText}
        </p>

      </div>
      <div>
         <img className='about-img' src={process.env.PUBLIC_URL + "/icons/Aeoliva-olive-oil-section-2.jpg"} alt="Olive Bottle"/>
      </div>
    </main>
  )
}

export default About
import React from 'react'
import '../style/home.css'


function Home(props) {
  return (
    <main className='main-home'>
      {/*<img className='main-img' src={process.env.PUBLIC_URL + "/icons/Aeoliva-olive-grove.jpg"} alt="Olive Trees"/>*/}
    </main>
  )
}

export default Home
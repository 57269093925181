import React from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import Layout from './Layout'
import Home from './components/Home'
import About from './components/About'
import Products from './components/Products'
import History from './components/History'
import Contact from './components/Contact'
import Cart from './components/Cart';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import Login from './components/Login';
import Admin from './components/Admin';
import Orders from './components/Orders';
import Profile from './components/Profile';
import AdminContent from './components/AdminContent';
import useDataContext from './hooks/useDataContext' 
import './App.css';

function App() {
  const {auth} = useDataContext()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"
  //console.log('from:',from)
  //console.log('app:', auth)
  return (
    <Routes>
      
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='products' element={<Products />} />
        <Route path='history' element={<History />} />
        <Route path='contact' element={<Contact />} />
        <Route path='cart' element={<Cart />} />
        
      </Route>
      
        
      <Route element={<PersistLogin />}>
        
        <Route element={<RequireAuth allowedUsers={100}/>}>
          <Route path="admin" element={<Admin />}>
            <Route index element={<AdminContent />} />
            <Route path="orders" element={<Orders />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path='/admin/login' element={auth?.user ? <Navigate to={`/admin`} replace/> : <Login />} />
      
        

      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App

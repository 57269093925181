import React, {useState} from 'react'
import useDataContext from '../hooks/useDataContext'
import { useLocation , useNavigate} from 'react-router-dom'
import axios from '../api/axiosapi'

const LOGIN_URL="login.php"

function Login() {
    const CLG = "a489f14e55b80a0f8b1fd51b6bc76d30"; // Check LoGin
    const {auth, setAuth} = useDataContext()
    
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"
    const [userName , setUserName]=useState(null)
    const [userPass, setUserPass] = useState(null)
    const [errMsg, setErrMsg] = useState('')
    //console.log('login:',auth)
   
    async function handleLogin(ev){
        ev.preventDefault()
        try{
            const response = await axios.post(LOGIN_URL,
                {username:userName, pass:userPass, act:CLG},
                {withCredentials:true}
            )
            const accessToken=response?.data?.accessToken || false
            const roles = response?.data?.roles 
            const userID = response?.data?.userID || false
            setAuth({user:userID, roles, accessToken})
            setUserName('')
            setUserPass('')
            navigate("/admin", {replace:true})
        }catch(err){
            if(!err?.response){
                console.log( "NO SERVER RESPONSE")
                setErrMsg("NO SERVER RESPONSE")
            }else if(err.response?.status === 409){
                console.log(err.response)
            }else{
                console.log(err?.response?.data?.message)
                setErrMsg(`(${err.response?.status}) ${err?.response?.data?.message}`)
            }
            //errRef.current.focus()
        }
    }
  return (
    <main>
        <h2>Login ...</h2>
        <form onSubmit={(e)=>handleLogin(e)}>
            <p>
                <label htmlFor='user_name'>username</label>
                <input type="text" name="userName" id="user_name" value={userName || ''} onChange={(e)=>setUserName(e.target.value)}/> 
            </p>
            <p>
                <label htmlFor='user_pass'>password</label>
                <input type="password" name="userPass"  id="user_pass" value={userPass || ''} onChange={(e)=>setUserPass(e.target.value)} />
            </p>
            <p>{errMsg}</p>
            <button type="submit">Login</button>
        </form>
    </main>
    
  )
}

export default Login
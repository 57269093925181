import textContentArr from './textContent.json'

export  function getPageText(pg){
    const page = textContentArr.find(p=>p.page===pg)
    const {content}=page
    return  content
}

export function currencyFormat( num, locale='en-US' ){
    const cf = new Intl.NumberFormat(locale,{currency:'EUR', style:'currency'})
    return cf.format(num)
    //el-GR
}

export function fullTextGR(value, lg='gb'){
    //const value=Math.floor(999999999999999*Math.random()).toString()+'.' +Math.floor(100*Math.random()).toString().padStart(2,'0')
    //const value='16.0' 
    //console.log(value)
    const result = []
    const str=value.toString()
    const arr = str.split('.')
    let dec='00'
    if(arr.length ===2){
        dec=arr[1]
    }
    dec=dec.padEnd(2,'0')
    let int = arr[0]
    //console.log(int, dec)
    const currency= parseInt(int) > 0 ? lg==='gr'?' ευρώ':parseInt(int)===1 ?' euro':' euros':''
    const rem = int.length % 3 ? 3:0
    const p=Math.floor(int.length / 3)
    int = int.padStart(p*3  + rem, '0')
    const group = int.match(/\d{1,3}/g)||[]
    group.reverse()
    const words=[
        ['','ένα','δύο','τρία','τέσσερα', 'πέντε','έξι','επτά','οκτώ','εννέα'],
        ['','δέκα','είκοσι','τριάντα', 'σαράντα', 'πενήντα', 'εξήντα','εβδομήντα', 'ογδόντα', 'ενενήντα'],
        ['','εκατόν','διακόσια','τριακόσια', 'τετρακόσια', 'πεντακόσια','εξακόσια','επτακόσια','οκτακόσια','εννιακόσια']
    ]
    const noun=['', 'χιλιάδες', 'εκατομύρια', 'δισεκατομύρια', 'τρισεκατομύρια']
    const wordsgb=[
        ['','one','two','three','four', 'five','six','seven','eight','nine'],
        ['','ten','twenty','thirty', 'forty', 'fifty', 'sixty','seventy', 'eighty', 'ninety'],
    ]
    const noungb=['', 'thousand', 'million', 'billion', 'trillion']

    let centstr=''
    let finalstr=''
    let nounstr=''
    //let k=0
    if(lg==='gr'){
        group.forEach((g,i)=>{
            
            const [h,d,u]=g.split('').map(j=>parseInt(j))
            //onsole.log(g, h,d,u)
            if(h===1 && d===0 && u===0){
               // k=1
                finalstr+='εκατό'
            }else if (d===1 && u===1){   
                finalstr+= h > 1 && i===1 ? words[2][h].slice(0,-1) + 'ες': words[2][h] 
                finalstr+=' έντεκα'
               // k=2
            }else if (d===1 && u ===2){
                finalstr+= h > 1 && i===1 ? words[2][h].slice(0,-1) + 'ες': words[2][h] 
                finalstr+=' δώδεκα'
                //k=3
            }else if (d===1 && u > 2){
                finalstr+= h > 1 && i===1 ? words[2][h].slice(0,-1) + 'ες': words[2][h] 
                finalstr+=' δεκα'
                finalstr+= i===1 && u===3 ? 'τρείς': i===1 && u===4 ? 'τέσσερεις':words[0][u] 
                //k=4
            }else if(i===1 && h===0 && d===0 && u===1){
                finalstr+='χίλια'
               // k=5
            }else if(i===1 && d!==1 && u===1){
                finalstr+= h > 1 ? words[2][h].slice(0,-1) + 'ες ': words[2][h] + ' '
                finalstr+=words[1][d] +' μία'
                //k=6
            }else{
                finalstr+= i === 1 && h >1 ? words[2][h].slice(0,-1) + 'ες ': words[2][h] + ' '
                //finalstr+=h > 1 ? words[2][h].slice(0,-1) + 'ες' +' ': words[2][h] + ' '
                finalstr+=words[1][d]+' '
                finalstr+= i===1 && u===3 ?'τρείς': i===1 && u===4 ?'τέσσερεις':words[0][u]
               // k=7
            }
            
            finalstr=finalstr.replace(/ {2}/g,' ').trim()//.replace(/ /g,'-')
            if(h*100 + d*10 + u ===0){
                nounstr=''
            }else if(i===1 && h*100 + d*10 + u >1){
                nounstr=noun[i]
            }else if(i>1){
                nounstr = h*100 + d*10 + u === 1 ? noun[i].slice(0,-1) + 'ο' :  noun[i]
            }
            finalstr+= ' ' + nounstr
            
            result.push(finalstr.trim())
            //console.log(g,finalstr.trim())
            finalstr=''
            nounstr=''
        })
    
        //console.log(group.reverse())
        //console.log(result)
        if(parseInt(dec) > 0){
            const dc=parseInt(dec[0])
            const uc=parseInt(dec[1])
            if(dc===1 && uc===1){
                centstr='έντεκα'
            }else if(dc===1 && uc===2){
                centstr='δώδεκα'
            }else if(dc===1 && uc > 2  ){
                centstr='δεκα'+words[0][uc]
            }else{
                centstr = dc > 0 ? words[1][dc]:'' 
                centstr+=' '+ words[0][uc]
            }
            centstr =centstr.trim() + ' '
            centstr+= parseInt(dec)===1?'λεπτό':'λεπτά'
        }
    }else{
        group.forEach((g,i)=>{
            const [h,d,u]=g.split('').map(j=>parseInt(j))
            if(h > 0){
                finalstr+=wordsgb[0][h] + ' hundred '
            }
            if(d===1 && u===0){
                finalstr+=' ten'
            }else if(d===1 && u===1){
                finalstr+=' eleven'
            }else if(d===1 && u===2){
                finalstr+=' twelve'
            }else if(d===1 && u === 3){
                finalstr+=' thirteen'
            }else if(d===1 && u === 5){
                finalstr+=' fifteen'
            }else if(d===1){
                finalstr+=' ' + wordsgb[0][u]+'teen'
            }else if(d>1){
                const tempstr= wordsgb[1][d] + ' ' + wordsgb[0][u]
                finalstr=finalstr + tempstr.trim().replace(/ /g,'-')
            }else{
                finalstr+= ' ' + wordsgb[0][u]
            }
            
            if(h*100 + d*10 + u ===0){
                nounstr=''
            }else{
                nounstr=noungb[i]
            }
            finalstr+=  ' '+nounstr
            result.push(finalstr.trim().replace(/ {2}/g,' '))
            //console.log(g,finalstr.trim(),k)
            finalstr=''
            nounstr=''
        })
        if(parseInt(dec) > 0){
            const dc=parseInt(dec[0])
            const uc=parseInt(dec[1])
            if(dc===1 && uc===0){
              centstr=wordsgb[1][dc]
            }else if(dc===1 && uc===1){
              centstr='eleven'
            }else if(dc===1 && uc===2){
              centstr='twelve'
            }else if(dc===1 && uc === 3 ){
              centstr='thirteen'
            }else if(dc===1 && uc === 5 ){
              centstr='fifteen'
            }else if(dc===1){
              centstr=wordsgb[0][uc]+'teen'
            }else if(dc>1){
              const tempstr= wordsgb[1][dc] + ' ' + wordsgb[0][uc]
              centstr=tempstr.trim().replace(/ /g,'-')
            }else{
              centstr=wordsgb[0][uc]
            }
            centstr =centstr.trim() + ' '
            centstr+= parseInt(dec)===1?'cent':'cents'
        }
    }
    const out=result.reverse().filter(r=>r!=='').join(' ') + currency
    const sep = lg==='gr'?' και ' : ' and '
    const rtn=[out,centstr].filter(r=>r!=='').join (sep)
    //console.log(out, centstr)
    //console.log(rtn)
    return rtn
    //console.log(group)

}

export function checkVATNumber(vat='', strictCheck=false){
    const regex = /[ _]/gi
    const tempVat = vat.replaceAll(regex, '')
    if(!tempVat.length){
        return null
    }
    const vatLength =tempVat.length 
    if(vatLength === 9){
        const tempVatArr = tempVat.split('')
        const checkDigit = parseInt(tempVatArr.pop())
        const res = (tempVatArr.reduce((acc, t,i)=>acc+=Math.pow(2,(8-i)) * parseInt(t),0)) % 11 
        return res ===10 && checkDigit===0 ? true : res === checkDigit
    }
    
    return strictCheck ? false : null 
}

import React, {useState, useRef, useEffect} from 'react'
import useDataContext from '../hooks/useDataContext' 
import {currencyFormat } from '../utils/util'
import Currency from 'currency.js'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CartItem(props) {
    const qtyRef = useRef(null)
    const {locale, item, checkout}=props
    const {increaseCartItems, decreaseCartItems, removeCartItems, updateCartItemsQuantity} = useDataContext()
    //console.log('item:', item)
    const subTotal = Currency(item.price).multiply(item.qty).value
    const [currentItem, setCurrentItem] =useState(item)

    function handleChange(ev){
        if(ev.target.value.trim().length > 3){
            return
        }
        const quantity = parseInt(ev.target.value)||''
        setCurrentItem(prev=>({...prev, qty:quantity}))
    }
    function handleKeyDown(ev){
        if(ev.key==='Enter' || ev.key==='NumpadEnter'){
            ev.target.blur()
        }
    }
    function updateQty(id, ev){
        const quantity = parseInt(ev.target.value)||1
        updateCartItemsQuantity(id,quantity)
    }

    useEffect(()=>{
        setCurrentItem(item)
      },[item])
  return (
    <li>
        <img className='cart-item-img' src={process.env.PUBLIC_URL + "/icons/" + item.img} alt="Olive Oil"/>
        <div className='cart-item-content'>
            <div className='cart-item-name'>{item.des}</div>
            <div className='cart-item-qty'>
                {!checkout?<button className='inc-dec-qty' onClick={(e)=>decreaseCartItems(item.id)}>&minus;</button>:''}
                { checkout ? 
                    <span className='qty-field'>{item.qty}</span>
                :
                    <input type='text' 
                    className='qty-field-inp' 
                    name='qty' 
                    value={currentItem.qty} 
                    onChange={(e)=>handleChange(e)} 
                    onKeyDown={(e)=>handleKeyDown(e)}
                    onBlur={(e)=>updateQty(currentItem.id, e)}
                    ref={qtyRef}
                    />
                }
                {!checkout?<button className='inc-dec-qty' onClick={(e)=>increaseCartItems(item.id)}>+</button>:''}
            </div>
            {parseFloat(item.price) ?
            <div className='cart-item-price'> &times; {currencyFormat(parseFloat(item.price), locale)}</div>
            :''
            }
            <div className='cart-item-subtotal'>
                {parseFloat(item.price)?currencyFormat(subTotal,locale):'Κ.Σ'}
            </div>
           
            <button className='remove-cart-item' disabled={checkout} onClick={(e)=>removeCartItems(item.id)}>
                <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </button>
        </div>
    </li>
  )
}

export default CartItem
import React, {useState,useEffect} from 'react'
import axios from '../api/axiosapi'
import { getPageText, currencyFormat } from '../utils/util'
import useDataContext from '../hooks/useDataContext'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/products.css'

function Products(props) {
  const DATA_URL="product.php"
  const GPRD = "2709e09afac98ce639c847361a3aa3ed" // Get PRoDucts
  const {lang, getCartItem, increaseCartItems, decreaseCartItems, removeCartItems, cartItems, updateCartItemsQuantity} = useDataContext()
  const locale = lang==='gr'?'el-GR':'en-US'
  const content = getPageText('products')
  const [products, setProducts]  = useState([])
  const [itemsInCart, setItemsInCart] = useState(cartItems)


  function handleChange(id, ev){
    if(ev.target.value.trim().length > 3){
      return
    }
    const  quantity = parseInt(ev.target.value) || ''
    const temp = itemsInCart.map(i=>{
      if(i.id===id){
        return {...i, qty:quantity}
      }else{
        return i
      }
    })
    setItemsInCart(temp)
  }
  function handleKeyDown(ev){
    if(ev.key==='Enter' || ev.key==='NumpadEnter'){
      ev.target.blur()
    }
  }

  function updateQty(id, ev){
    const quantity = parseInt(ev.target.value)||1
    updateCartItemsQuantity(id,quantity)
}

  useEffect(() => {
    async function getProducts(){ 
      try {
        const response= await axios.post(DATA_URL, {act:GPRD,lang})
        
        const {products} = response.data
        setProducts(products)
        //console.log('options',options )
        
      }catch(err){
        const {error, message} =err?.response?.data
        console.log(error , message)
      }
    }
    getProducts()
    
  }, [lang])

  useEffect(()=>{
    setItemsInCart(cartItems)
  },[cartItems])

  const productItems = products.map(p=>{
    const cartItem = getCartItem(p.id)
    return(
      <div className="product-content" key={`p${p.id}`}>
        <img className='product-img' src={process.env.PUBLIC_URL + "/icons/" + p.img_url} alt="Olive Oil"/>
        <div className='product-cart'>
          { cartItem ?
              <>
                <div className="quantity-bar">
                  <button className="incdec-btn" onClick={(e)=>decreaseCartItems(p.id)}>-</button>
                  {/* <span className='quantity-text'>{cartItem.qty }</span> */}
                  <input 
                    className='quantity-text-inp'
                    name="qty"
                    value={itemsInCart?.find(i=>i.id===cartItem.id)?.qty||''}
                    onChange={(ev)=>handleChange(cartItem.id, ev)}
                    onKeyDown={(e)=>handleKeyDown(e)}
                    onBlur={(e)=>updateQty(cartItem.id, e)}
                  /> 
                  <button className="incdec-btn" onClick={(e)=>increaseCartItems(p.id)}>+</button>
                </div> 
                <button className="remove-btn" onClick={(e)=>removeCartItems(p.id)}>
                <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                </button>
              </>
              : 
              <button className="addtocart-btn" onClick={(e)=>increaseCartItems(p.id)}>
                <FontAwesomeIcon icon="fa-solid fa-cart-shopping"/>
                <span>{content[lang].cartButtonText}</span>
              </button>
          }
        </div>
        <div className='product-description'>
          <h3 className='product-name'>{p.des}
            {p.price ? <span className='price'>{currencyFormat(p.price, locale)}</span>:''}
          </h3>
          <p>
            {p.details}
          </p>
        </div>
        
      </div>
  )})
  //console.log(itemsInCart)

  return (
    <main className="products-main">
      <h1>{content[lang].title}</h1>
      <h5>{content[lang].subtitle}</h5>
      {
        !products.length ?
        <div className='loader'>Load<FontAwesomeIcon className='dots' icon="fa-solid fa-ellipsis" fade /></div>
        :
        <div className="products-container">
          {productItems}
        </div>
      }
      {/* <div className='loader'>Load<FontAwesomeIcon className='dots' icon="fa-solid fa-ellipsis" fade /></div> */}
    </main>
  )
}

export default Products
import React,{useEffect} from 'react'
//import useDataContext from '../hooks/useDataContext'
import {Link,NavLink, Outlet}  from 'react-router-dom'
//import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from '../api/axiosapi';
import useDataContext from '../hooks/useDataContext'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../media/img/Aeoliva-olive-oil-logo.svg'
import "../style/admin.css"
function Admin() {
   const {setAuth} = useDataContext()
   // const axiosPrivate = useAxiosPrivate()
    

    useEffect(() => {
        sessionStorage.clear()
        //console.log('clear session')
        //console.log(auth)
    }, [])
    async function logOut(e){
        try{
            const response = await axios.get("logout.php", {
                withCredentials:true
            });
            sessionStorage.clear()
            setAuth({})
            console.log(response)
            //navigate('/', {replace:true}); // from
        }catch(err){
            console.log("logout error:", err)
        }
    }
    
    //console.log('admin:', auth,location)
  return (
    <div>
        <header>
            <Link to="/" >
                <img src={logo} className='logo' alt="AEOLIVA Logo" />
            </Link>
            <nav className={`admin-nav`}>
                <ul>
                    <li><NavLink to='orders' className={({isActive}) => isActive ? "active":""}>
                            <span>Παραγγελίες</span>
                            <FontAwesomeIcon icon="fa-solid fa-cart-arrow-down" className='menu-icon'/>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='profile'>
                            <span>Χρήστης</span>
                            <FontAwesomeIcon icon="fa-solid fa-user" className='menu-icon'/>
                        </NavLink>
                    </li>
                </ul>
                
            </nav>
            <button className='disconnect' onClick={(e)=>logOut(e)}>
                <span>Αποσύνδεση</span>
                <FontAwesomeIcon icon="fa-solid fa-user" className='menu-icon'/>
            </button>
        </header>
        <Outlet />
       {/*  <h2>Admin Page here...</h2>

        <Link to="/">Return</Link>
        <Link to="/admin/login" state={{ from: location}} replace >Login</Link> */}
        
       
    </div>

    
  )
}

export default Admin
import React from 'react'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPageText } from '../utils/util'
import useDataContext from '../hooks/useDataContext'
import '../style/contact.css'

function Contact(props) {

  const {lang} = useDataContext()
  const content = getPageText('contact')

  return (
    <main className='contact-main'>
      <h1>{content[lang].title}</h1>
      <div className="contact-container">
        <div className="contact-info">
          <p>
            {content[lang].infoLine1}<br /> 
            {content[lang].infoLine2}<br />
            {content[lang].infoLine3}<br />
            {content[lang].infoLine4}
          </p>
          <div className="social-net">
           {/*  <a href='#' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
            </a>
            <a className='ext-link' href='#' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
            </a>
            <a className='ext-link' href='#' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
            </a> */}
             
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
            
            
              <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
            
           
              <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
            
            
          </div>
        </div>
        <div className="contact-form">
          <div className='name-field'> 
            <label htmlFor='fullname'>{content[lang].fullName}</label>
            <input type="text" name="fullname" id="fullname" />
          </div>
          <div className='mail-field'> 
            <label htmlFor='email'>{content[lang].email}</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className='tel-field'> 
            <label htmlFor='contact_number'>{content[lang].contactNumber}</label>
            <input type="text" name="contact_number" id="contact_number" />
          </div>
          <div className='message-field'> 
            <label htmlFor='message_text'>{content[lang].message}</label>
            <textarea name="message_text" id="message_text" />
          </div>
          <div className='submit-field'>
            <button type="button" className='send-message'>{content[lang].sendButton}</button>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact

